body {
  font-family: Arial, sans-serif;
  background-color: #0f0f0e;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

h1 {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #ffffff;
}

p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  color: #ffffff;
}

.error-page button {
  background-color: #007bff;
  color: #ffffff;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 1rem;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  background-color: #3f51b5;
  color: #0a0101;
  padding: 0.5rem 2rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  
}

.error-page button:hover {
  background-color: #2c3e50;
}