/* Set the container max-width and center it horizontally */
.container {
  max-width: 800px;
  margin: 0 auto;
}

/* Style the header */
.header {
  padding: 20px;
  text-align: center;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin-bottom: 2rem;
}

.header__title {
  color: white;
  font-size: 2rem;
}

/* Set the home section height to 100% of the viewport height */
.home {
  position: center;
  height: 100vh;
}

/* Set the video player background image to cover the whole screen */
.fullscreen-bg {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  overflow: hidden;
  background-attachment: fixed;
  background-color: #0f0f0e;
}

/* Set the video player background image to cover the whole screen */
.fullscreen-bg__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* Style the title and description in the home section */
.home__title,
.home__description {
  background-color: #0f0f0e;
  color: rgb(131, 127, 127);
  font-size: 2rem;
  padding: 20px;
  text-align: center;
  
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  
}

/* Center the form in the home section */

/* .home__form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
} */

.home__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

input[type="text"] {
  width: 250px;
  padding: 10px;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.home__form form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 300px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-control {
  width: 300px;
  padding: 0.5rem;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
  color: #3f51b5
}

.btn {
  background-color: #007bff;
  color: #ffffff;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 1rem;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  background-color: #3f51b5;
  color: #0a0101;
  padding: 0.5rem 2rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  
}

.btn:hover {
  background-color: #2c3e50;
}

/* Style the video player */
.video-player {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Set the video player container to be centered */
.video-player:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  background-color: #0f0f0e;
}

/* Style the video player iframe */
.video-player__iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Set the video player background to cover the whole screen */
.video-player__container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 80%;
  height: 80%;
  z-index: -1;
}

/* Style the footer */
.footer {
  font-size: 4px;
  padding: 20px;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 30%;
  background-color: #f2f2f2;
  
}




@media only screen and (max-width: 600px) {
  .footer {
    font-size: 14px;
    padding: 10px;
  }

  .header {
    font-size: 20px;
    padding: 10px;
  }
}
